<template>
  <v-container>
    <v-data-table
      :headers="headers[$vuetify.breakpoint.xsOnly]"
      :items="items"
      :items-per-page="7"
      item-key="code"
      sort-by="name"
      mobile-breakpoint="0"
      class="table-cursor"
      :search="search"
      loading-text="Cargando ..."
      @click:row="handleClick"
    >
      <template v-slot:[`item.picture`]="{ item }" class="pa-0 ma-0">
        <v-avatar>
          <v-img
            :src="getImagePhoto(item.account, item.code, item.picture)"
          ></v-img>
        </v-avatar>
      </template>
      <template v-slot:[`item.name`]="{ item }" class="pa-0 ma-0">
        <b>{{ item.name }}</b>
        <small v-if="item.menus">
          Foto Menú
        </small>
        <span v-if="$vuetify.breakpoint.xsOnly">
          <small> ({{ item.group_name }})</small><br />
          <small> {{ item.address }} {{ item.mobile }} </small>
        </span>
      </template>
    </v-data-table>
    <v-btn color="orange" class="float" fab x-small dark @click="newForm()">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-bottom-navigation dark app>
      <v-toolbar color="#075E54">
        <v-row justify="center">
          <v-col md="6" cols="12">
            <v-text-field
              v-model="search"
              hide-details
              append-icon="mdi-magnify"
              single-line
              dense
              outlined
              rounded
              placeholder="Buscar proveedor"
            />
          </v-col>
        </v-row>
      </v-toolbar>
    </v-bottom-navigation>

    <PartyForm
      :table="table"
      :item="item"
      :dialog="dialog"
      :newItem="newItem"
      @close="dialog = false"
      @refresh="refresh()"
    />

    <v-dialog v-model="loading_status" hide-overlay persistent width="300">
      <v-card color="primary" dark>
        <v-card-text>
          Cargando ...
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import { webserver } from "../../services/webserver.js";
import createItem from "../../utils/createParty.js";
import PartyForm from "../../components/PartyForm.vue";
export default {
  components: { PartyForm },
  mounted() {
    this.get_items();
    console.log(this.$vuetify.breakpoint.xsOnly);
  },
  data() {
    return {
      table: "suppliers",
      dialog: false,
      newItem: false,
      search: "",
      loading_status: false,
      errors: [],
      items: [],
      item: createItem(),
      headers: {
        false: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Grupo",
            align: "start",
            sortable: true,
            value: "group_name",
            dataType: "text",
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
          {
            text: "Dirección",
            align: "start",
            sortable: true,
            value: "address",
            dataType: "text",
          },
          {
            text: "Tel.",
            align: "end",
            value: "mobile",
            dataType: "text",
            width: 150,
          },
        ],
        true: [
          {
            text: "",
            align: "start",
            sortable: true,
            value: "picture",
            dataType: "text",
            width: 40,
          },
          {
            text: "Nombre",
            align: "start",
            sortable: true,
            value: "name",
            dataType: "text",
          },
        ],
      },
    };
  },
  methods: {
    openURL(e) {
      window.open(e, "", "width=400,height=800");
    },
    handleClick(data) {
      this.item = { ...data };
      this.newItem = false;
      this.dialog = true;
    },
    updateLocation(e) {
      this.store.location = e;
    },
    get_items() {
      var qry = {
        account: this.$store.state.profile.account,
        table: this.table,
        filters: [],
      };
      this.loading_status = true;
      console.log(qry);
      webserver("get_table", qry, (data) => {
        //console.log(data);
        this.loading_status = false;
        this.items = data;
        if (data.length > 0) {
          this.items = data;
        } else {
          this.newForm();
        }
      });
    },
    refresh() {
      this.dialog = false;
      this.get_items();
    },
    getImagePhoto: function (account, code, src) {
      var url =
        "https://smartchef.pro/photos/" + account + "/" + code + "/" + src;
      return url;
    },
    newForm() {
      this.item = createItem();
      this.item.account = this.$store.state.profile.account;
      this.item.location = "";
      this.valid = false;
      this.newItem = true;
      this.dialog = true;
    },
    call_contact(data) {
      window.location.href = "tel://" + data;
    },
  },
  computed: {
    isMobile() {
      if (screen.width <= 760) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style lang="scss" >
.float {
  position: fixed;
  width: 40px;
  height: 40px;
  top: 64px;
  right: 20px;
  background-color: #0c9;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  box-shadow: 2px 2px 3px #999;
}
.text-start {
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
</style>
